import React, { useState } from 'react'
import { Field, FieldArray } from 'formik'
import { Form, Group, Input, Select, Tags, Modal } from '@peracto/peracto-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { toast } from 'react-toastify'

import { DELETE, useClient } from '@peracto/client'

import * as S from './styled'

const AddressForm = ({
    values,
    visibleFields,
    setVisibleFields,
    countries,
    titles,
    onSaveAddress,
    setFormData,
    ...props
}) => {
    const [showAddressDialog, setShowAddressDialog] = useState(false)
    const [addressDialogContent, setAddressDialogContent] = useState({})
    const { client } = useClient()

    const addressTypes = [
        { label: 'Delivery', value: 'delivery' },
        { label: 'Billing', value: 'billing' },
    ]

    const addressRow = {
        title: '',
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
        type: '',
    }

    const onDeleteAddress = async addressId => {
        try {
            await client(DELETE, 'addresses', {
                id: addressId,
            })

            toast.success('Address deleted successfully!')

            setFormData({
                ...values,
                addresses: values.addresses.filter(address => address['@id'] !== addressId),
            })

            setVisibleFields({})
            setShowAddressDialog(false)
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    const getCountry = value => {
        const country = countries.find(country => value === country.iso3)
        return country ? country.label : value
    }

    const getCountryOptions = type => {
        return countries
            .filter(country => {
                if (
                    (!country.validForBilling && type === 'delivery') ||
                    (!country.validForDelivery && type === 'billing')
                ) {
                    return false
                }

                return true
            })
            .map(country => ({ label: country.label, value: country.iso3 }))
    }

    return (
        <>
            <Form autoComplete="off" values={values} showActionPanel={false} {...props}>
                <Group key="addresses" id="addresses" name="Addresses">
                    <Field name="addresses">
                        {({ form, field }) => (
                            <FieldArray name={field.name}>
                                {({ push }) => (
                                    <div>
                                        {field.value.length > 0 ? (
                                            field.value.map((address, idx) => (
                                                <div
                                                    className="mb-3 d-flex justify-content-between align-items-start"
                                                    key={`address-${address?.originId ||
                                                        `address-${idx}`}`}
                                                >
                                                    {!visibleFields[`address-${idx}`] && (
                                                        <S.Address>
                                                            <p className="address-name">
                                                                {address.title} {address.firstName}{' '}
                                                                {address.lastName}
                                                            </p>
                                                            {address?.company && (
                                                                <p>{address.company}</p>
                                                            )}
                                                            {address.addressLine1 && (
                                                                <p>{address.addressLine1}</p>
                                                            )}
                                                            {address.addressLine2 && (
                                                                <p>{address.addressLine2}</p>
                                                            )}
                                                            {address.addressLine3 && (
                                                                <p>{address.addressLine3}</p>
                                                            )}
                                                            {address.addressLine4 && (
                                                                <p>{address.addressLine4}</p>
                                                            )}
                                                            {address.city && <p>{address.city}</p>}
                                                            {address.county && (
                                                                <p>{address.county}</p>
                                                            )}
                                                            {address.postcode && (
                                                                <p>{address.postcode}</p>
                                                            )}
                                                            {address.country && (
                                                                <p>{getCountry(address.country)}</p>
                                                            )}
                                                        </S.Address>
                                                    )}

                                                    {visibleFields[`address-${idx}`] && (
                                                        <div className="w-100">
                                                            <Tags
                                                                name={`addresses.${idx}.type`}
                                                                label="Address Type"
                                                                options={addressTypes}
                                                                placeholder="Select an address type"
                                                                handleChange={() =>
                                                                    form.setFieldValue(
                                                                        `addresses.${idx}.country`,
                                                                        'GBR'
                                                                    )
                                                                }
                                                            />
                                                            <Select
                                                                name={`addresses.${idx}.title`}
                                                                label="Title"
                                                                options={titles}
                                                                placeholder="Select a title"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.firstName`}
                                                                label="First name"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.lastName`}
                                                                label="Last name"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.company`}
                                                                label="Company"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine1`}
                                                                label="Address Line 1"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine2`}
                                                                label="Address Line 2"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine3`}
                                                                label="Address Line 3"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.addressLine4`}
                                                                label="Address Line 4"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.city`}
                                                                label="City"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.county`}
                                                                label="County"
                                                            />
                                                            <Input
                                                                name={`addresses.${idx}.postcode`}
                                                                label="Postcode"
                                                            />
                                                            <Select
                                                                name={`addresses.${idx}.country`}
                                                                label="Country"
                                                                options={getCountryOptions(
                                                                    field.value[idx].type
                                                                )}
                                                                placeholder="Select a country"
                                                            />

                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-success d-flex align-items-center"
                                                                    onClick={() => {
                                                                        onSaveAddress(address)
                                                                        setVisibleFields({
                                                                            ...visibleFields,
                                                                            [`address-${idx}`]: false,
                                                                        })
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faSave}
                                                                        className="mr-2"
                                                                    />{' '}
                                                                    Save Address
                                                                </button>

                                                                {field.value[idx].id && (
                                                                    <button
                                                                        className="ml-2 btn btn-outline-danger d-flex align-items-center"
                                                                        onClick={() => {
                                                                            setAddressDialogContent(
                                                                                address
                                                                            )
                                                                            setShowAddressDialog(
                                                                                true
                                                                            )
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faTrash}
                                                                            className="mr-2"
                                                                        />{' '}
                                                                        Delete Address
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <button
                                                        className="p-0 btn btn-link d-flex align-items-center"
                                                        onClick={() =>
                                                            setVisibleFields({
                                                                ...visibleFields,
                                                                [`address-${idx}`]: !visibleFields[
                                                                    `address-${idx}`
                                                                ],
                                                            })
                                                        }
                                                    >
                                                        {visibleFields[`address-${idx}`] ? (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={faTimes}
                                                                    className="mr-2"
                                                                />
                                                                Close
                                                            </>
                                                        ) : (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    className="mr-2"
                                                                />
                                                                Edit
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="mt-3 mb-0 text-center">
                                                No addresses are assigned to this user.
                                            </p>
                                        )}

                                        <div className="mb-2 d-flex justify-content-end">
                                            <button
                                                className="my-2 btn btn-sm btn-outline-primary"
                                                onClick={() => {
                                                    push(addressRow)
                                                    setVisibleFields({
                                                        ...visibleFields,
                                                        [`address-${field.value.length}`]: true,
                                                    })
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} className="mr-2" />{' '}
                                                Add New Address
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        )}
                    </Field>
                </Group>
            </Form>

            <Modal
                isVisible={showAddressDialog}
                title="Delete User"
                close={() => setShowAddressDialog(false)}
                buttons={[
                    {
                        type: 'btn-outline-secondary',
                        text: 'Close',
                        action: () => setShowAddressDialog(false),
                    },
                    {
                        type: 'btn-danger',
                        text: 'Delete Address',
                        action: () => onDeleteAddress(addressDialogContent['@id']),
                    },
                ]}
            >
                <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className="mb-4 d-block" />
                Are you sure you would like to permanently delete the address for{' '}
                {addressDialogContent.title} {addressDialogContent.firstName}{' '}
                {addressDialogContent.lastName} ?
            </Modal>
        </>
    )
}

export default AddressForm
