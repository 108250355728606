import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";

import { LocationsForm } from "./packages/Locations";
import { ProductForm } from "./packages/Products";
import { UserForm } from "./packages/User";

import blocks from "./blocks";
import { menu } from "./menu";

const config = new Config({
  api: process.env.REACT_APP_API,
  algolia: {
    applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
  },
  modules: [
    PeractoUser({ UserForm }),
    PeractoCommon(),
    PeractoCatalogue({ ProductForm }),
    PeractoLocations({ LocationsForm }),
    PeractoPromotions(),
    PeractoContent(),
  ],
  editorConfig: {
    theme: EditorTheme,
    colorOptions: [
      { label: "Brand Blue", value: EditorTheme.colors.brand.blue },
      { label: "Brand Green", value: EditorTheme.colors.brand.green },
      { label: "Brand Orange", value: EditorTheme.colors.brand.orange },
      { label: "Brand Success", value: EditorTheme.colors.brand.success },
      { label: "Brand Danger", value: EditorTheme.colors.brand.danger },
      { label: "Brand Black", value: EditorTheme.colors.brand.black },
      {
        label: "Brand Dark Grey",
        value: EditorTheme.colors.brand.darkGrey,
      },
      { label: "Brand Grey", value: EditorTheme.colors.brand.grey },
      {
        label: "Brand Light Grey",
        value: EditorTheme.colors.brand.lightGrey,
      },
    ],
    locales: ["en_GB"],
    blocks,
    inputTypes: {},
    stylesheets: [
      "https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap",
    ],
    globalStyles: GlobalStyles,
  },
  features: {
    shippingServices: {
      deliveryDays: true,
      conditions: true,
    },
    products: {
      exportProductData: true,
      productVariants: true,
      hideClearancePrice: true,
    },
    user: {
      companySettings: true,
    },
  },
  menu,
});

const App = () => (
  <ThemeProvider theme={AdminTheme}>
    <Peracto config={config} />
  </ThemeProvider>
);

export default App;
